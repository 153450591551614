import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Symbol
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
//import { wrangleSp41Data } from '../../utils/helpers'

const Sp41OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  //const sp41Data = wrangleSp41Data(data)
  const baseline = data[0].VALUE_NUM.toFixed()
  const mostRecentValue = data[data.length - 1].VALUE_NUM
  const lastYearsValue = data[data.length - 2].VALUE_NUM
  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      {matches ? <Label>{t('mean across antigens')}</Label> : null}
      <ChartRow>
        {!matches ? <Label>{t('mean across antigens')}</Label> : null}
        <Bar
          role="img"
          alt={`${t(
            'horizontal bar chart showing'
          )} ${mostRecentValue.toFixed()}%.`}
        >
          <Baseline left={baseline} />
          <Progress
            progress={mostRecentValue.toFixed()}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{mostRecentValue.toFixed()}%</BarLabel>
          </Progress>
        </Bar>
        <Symbol>
          <BsFillExclamationDiamondFill color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp41OverviewChart
