import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
//import { NUM_COUNTRIES_IN_REG, GBL_NUM_COUNTRIES } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Progress,
  BarLabel,
  Baseline,
  Symbol
} from '../../styles/OverviewChartStyles'
import { IoCheckmarkCircle } from 'react-icons/io5'
//import { wrangleSP12Data } from '../../utils/helpers'

const Sp12OverviewChart = ({ data, region }) => {
  const { t } = useTranslation()

  //const sp12Data = wrangleSP12Data(data)
  const scale = 200
  const baselineValue = data[0].VALUE_NUM
  const baselinePercent = (data[0].VALUE_NUM / scale) * 100
  const mostRecentPercent = (data[data.length - 1].VALUE_NUM / scale) * 100
  const mostRecentValue = data[data.length - 1].VALUE_NUM
  const lastYearsValue =
    data.length > 1 ? data[data.length - 2].VALUE_NUM : baselineValue

  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      <ChartRow>
        {/* spacing placeholder */}
        {!matches ? <Label></Label> : null}
        <Bar
          role="img"
          alt={`${t('horizontal bar chart showing the')} ${mostRecentValue} ${t(
            'physicians, nurses and midwives per 10,000 population.'
          )}`}
        >
          <Baseline left={baselinePercent} />
          <Progress
            progress={mostRecentPercent}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{mostRecentValue.toFixed()}</BarLabel>
          </Progress>
        </Bar>
        <Symbol>
          {/* this is invisible placeholder so spacing between bars comes out right */}
          <IoCheckmarkCircle color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp12OverviewChart
