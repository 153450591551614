import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Symbol
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { group } from 'd3-array'

const Sp63OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  // const wrangleData = () => {
  //   const allYears = []
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'region']

  //       if (!keysToExclude.includes(key) && value) {
  //         const years = key.slice(-8)
  //         const year1 = years.slice(0, 4)
  //         const year2 = years.slice(-4)
  //         const yearWithHyphen = `${year1}-${year2}`
  //         let category
  //         //gsheets plugin makes key start with lower case, so this identifies the beginning of a key
  //         if (key.includes('decrease')) {
  //           category = 1
  //         } else if (key.includes('increase')) {
  //           category = 3
  //         } else category = 2
  //         allYears.push(yearWithHyphen)
  //         return { year: yearWithHyphen, category, value }
  //       } else return null
  //     })

  //     .filter((item) => item)

  //   const years = Array.from(new Set(allYears))

  //   return years.map((year) => {
  //     const total = allData
  //       .filter((d) => d.year === year)
  //       .reduce((acc, d) => {
  //         return acc + d.value
  //       }, 0)

  //     const categoriesToInclude = [2, 3]
  //     const subtotal = allData
  //       .filter(
  //         (d) => d.year === year && categoriesToInclude.includes(d.category)
  //       )
  //       .reduce((acc, d) => {
  //         return acc + d.value
  //       }, 0)
  //     return { year, total, subtotal }
  //   })
  // }
  //const sp63Data = wrangleData()

  const wrangleData = (data) => {
    const dataMap = group(data, (d) => d.YEAR_RANGE ?? d.YEAR)
    const dataByYear = Array.from(dataMap.values())
    return dataByYear.map((arr) => {
      const categoriesToInclude = ['Stable', 'Increased']
      const subtotal = arr
        .filter((item) => categoriesToInclude.includes(item.DIMENSION2))
        .reduce((acc, d) => {
          return acc + d.VALUE_NUM
        }, 0)
      const year = arr[0].YEAR_RANGE ?? arr[0].YEAR
      return { year, subtotal }
    })
  }
  const sp63Data = wrangleData(data)

  const baseline = sp63Data[0].subtotal
  const mostRecentValue = sp63Data[sp63Data.length - 1].subtotal
  const lastYearsValue = sp63Data[sp63Data.length - 2].subtotal
  const matches = useMediaQuery({ query: '(max-width: 740px)' })
  return (
    <VizContainer>
      <ChartRow>
        {/* spacing placeholder */}
        {!matches ? <Label></Label> : null}
        <Bar
          role="img"
          alt={`${t(
            'horizontal bar chart showing'
          )} ${mostRecentValue.toFixed()} ${t(
            'of countries whose share of national immunization schedule vaccine expenditure funded by domestic government resources increased or remained stable.'
          )}`}
        >
          <Baseline left={baseline} />
          <Progress
            progress={mostRecentValue}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{`${mostRecentValue.toFixed()}%`}</BarLabel>
          </Progress>
        </Bar>
        <Symbol>
          <BsFillExclamationDiamondFill color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp63OverviewChart
