import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'

import { NUM_YEARS } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Target,
  Symbol,
  IconContainer,
  LeftArrow,
  ArrowHead,
  BaselineLabel,
  ArrowContainer
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
//import { wrangle22Data } from '../../utils/helpers'

const Ig22OverviewChart = ({ data, regional }) => {
  const { t } = useTranslation()
  // const wrangleData = (data) => {
  //   const allYears = []
  //   const total = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'region']
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         //2020 is baseline so only show data after baseline
  //         if (year > 2020) {
  //           allYears.push(year)
  //           return { year, value }
  //         } else return null
  //       } else return null
  //     })
  //     .filter((item) => item)
  // const ig22Data =wrangle22Data(data)
  // console.log('ig22 ov', ig22Data)
  //only count values starting in 2021
  const total = data?.reduce((sum, d) => {
    sum += d.YEAR >= 2021 ? d.VALUE_NUM : 0
    return sum
  }, 0)

  //don't count first year in number of elapsed years
  const numYears = data.length - 1

  const goal = 500
  const goalPerYear = goal / NUM_YEARS
  const totalNeededToBeOnTrack = numYears * goalPerYear
  const sufficientData = numYears > 0
  const onTarget = total >= totalNeededToBeOnTrack

  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      {matches ? <Label>{t('cumulative vaccine introductions')}</Label> : null}
      <ChartRow>
        {!matches ? (
          <Label>{t('cumulative vaccine introductions')}</Label>
        ) : null}
        <Bar
          //rounded={regional ? false : true}
          //right={true}
          mr={regional ? null : '2.9rem'}
          role="img"
          alt={`${t('horizontal bar chart showing')} ${total} ${t(
            'vaccine introductions out of goal of 500.'
          )}`}
        >
          <Baseline left={(totalNeededToBeOnTrack / goal) * 100} />
          <Progress
            progress={Math.min((total / goal) * 100, 100)}
            color={regional ? 'gray' : onTarget ? 'brightBlue' : 'darkOrange'}
            //rounded={regional ? false : total / goal > 97 ? true : false}
            // right={true}
          >
            {(total / goal) * 100 >= 4 && (
              <BarLabel noPadding={(total / goal) * 100 < 5}>{total}</BarLabel>
            )}
          </Progress>

          {regional ? null : (
            <Target right={'-2.9rem'} success={total >= goal}>
              500
            </Target>
          )}
        </Bar>
        <Symbol>
          {regional ? null : sufficientData ? (
            onTarget ? (
              <IoCheckmarkCircle
                color={'#4681de'}
                size={42}
                role="img"
                alt="on target"
              />
            ) : (
              <BsFillExclamationDiamondFill
                color={'#d96038'}
                size={42}
                role="img"
                alt="off target"
              />
            )
          ) : (
            <BsFillExclamationDiamondFill color={'transparent'} size={42} />
          )}
        </Symbol>
      </ChartRow>
      <ChartRow marginTop={'-1rem'}>
        {!matches ? <Label></Label> : null}

        <IconContainer
          position={'flex-start'}
          marginRight={'2.9rem'}
          widthSm={'65%'}
          widthXs={'54%'}
        >
          <ArrowContainer left={(totalNeededToBeOnTrack / goal) * 100 + 2.5}>
            <ArrowHead />
            <LeftArrow />
          </ArrowContainer>
          <BaselineLabel
            left={(totalNeededToBeOnTrack / goal) * 100 + 5}
            offset={'-23px'}
          >
            {t(
              'cumulative vaccine introductions as of 2022 to be on track towards 2030 target'
            )}
            &nbsp;
          </BaselineLabel>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig22OverviewChart
