import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Symbol
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
//import { wrangleSP62Data } from '../../utils/helpers'
import { group } from 'd3-array'

const Sp62OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  //const sp62Data = wrangleSP62Data(data)
  const wrangleData = (data) => {
    const dataMap = group(data, (d) => d.YEAR_RANGE ?? d.YEAR)
    const dataByYear = Array.from(dataMap.values())
    return dataByYear.map((arr) => {
      const categoriesToInclude = ['Stable', 'Increased']
      const subtotal = arr
        .filter((item) => categoriesToInclude.includes(item.DIMENSION2))
        .reduce((acc, d) => {
          return acc + d.VALUE_NUM
        }, 0)
      const year = arr[0].YEAR_RANGE ?? arr[0].YEAR
      return { year, subtotal }
    })
  }
  const sp62Data = wrangleData(data)

  const baseline = sp62Data[0].subtotal
  const mostRecentValue = sp62Data[sp62Data.length - 1].subtotal
  const lastYearsValue =
    sp62Data.length > 1 ? sp62Data[sp62Data.length - 2].subtotal : baseline

  const matches = useMediaQuery({ query: '(max-width: 740px)' })
  return (
    <VizContainer>
      <ChartRow>
        {/* spacing placeholder */}
        {!matches ? <Label></Label> : null}
        <Bar
          role="img"
          alt={`${t(
            'horizontal bar chart showing'
          )} ${mostRecentValue.toFixed()} ${t(
            '% of countries whose domestic government and donor expenditure on primary health care increased or remained stable.'
          )}`}
        >
          <Baseline left={baseline} />
          <Progress
            progress={mostRecentValue}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{`${mostRecentValue.toFixed()}%`}</BarLabel>
          </Progress>
        </Bar>
        <Symbol>
          <BsFillExclamationDiamondFill color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp62OverviewChart
