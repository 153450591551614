import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  OverviewContainer,
  IntroText,
  GoalContainer,
  SectionTitle,
  GoalBox,
  GoalId,
  GoalDescription,
  PriorityDescription,
  NoDataPlaceholder,
  NoData,
  IconBox,
  PointerLink
} from '../styles/OverviewStyles'
import { PointerIcon } from '../components/PointerIcon'

import { MdHeadline } from '../styles/SharedStyles'
//import NavContext from '../components/NavContext'
import PageHeader from '../components/PageHeader'
import HowToRead from '../components/HowToRead'
import Explore from '../components/Explore'
import Ig11OverviewChart from '../components/OverviewCharts/Ig11OverviewChart'
import Ig12OverviewChart from '../components/OverviewCharts/Ig12OverviewChart'
import Ig13OverviewChart from '../components/OverviewCharts/Ig13OverviewChart'
import Ig21OverviewChart from '../components/OverviewCharts/Ig21OverviewChart'
import Ig22OverviewChart from '../components/OverviewCharts/Ig22OverviewChart'
import Ig31OverviewChart from '../components/OverviewCharts/Ig31OverviewChart'
import Ig32OverviewChart from '../components/OverviewCharts/Ig32OverviewChart'
import Sp12OverviewChart from '../components/OverviewCharts/Sp12OverviewChart'
import Sp16OverviewChart from '../components/OverviewCharts/Sp16OverviewChart'
import Sp21OverviewChart from '../components/OverviewCharts/Sp21OverviewChart'
import Sp22OverviewChart from '../components/OverviewCharts/Sp22OverviewChart'
import Sp32OverviewChart from '../components/OverviewCharts/Sp32OverviewChart'
import Sp41OverviewChart from '../components/OverviewCharts/Sp41OverviewChart'
import Sp51OverviewChart from '../components/OverviewCharts/Sp51OverviewChart'
import Sp62OverviewChart from '../components/OverviewCharts/Sp62OverviewChart'
import Sp63OverviewChart from '../components/OverviewCharts/Sp63OverviewChart'
import Sp71OverviewChart from '../components/OverviewCharts/Sp71OverviewChart'
import Footer from '../components/Footer'

// const Page = styled.div`
//   //overflow-y: ${({ isMobileOpen }) => (isMobileOpen ? 'hidden' : 'unset')};
// `
//this is the home page: the overview page for global data
const IndexPage = ({ data }) => {
  // const { isMobileOpen } = useContext(NavContext)
  const { t } = useTranslation()
  const {
    ig11,
    ig12,
    ig13,
    ig21,
    ig22,
    ig31,
    ig32,
    sp12,
    sp16,
    sp21,
    sp22,
    sp32,
    sp41,
    sp51,
    sp62,
    sp63,
    sp71,
    content,
    footerContent
  } = data
  return (
    <>
      <PageHeader title={'overview dashboard'} sectionId={'Overview'} />
      <OverviewContainer>
        {/* Impact Goals */}
        <MdHeadline color={'baseBlue'}>
          {t('Impact Goal Indicators')}
          {` — ${t('global')}`}
        </MdHeadline>
        <IntroText dangerouslySetInnerHTML={{ __html: content.igIntroRt }} />
        <HowToRead type={'ig'} />
        {/* impact goal 1 */}
        <GoalContainer>
          <SectionTitle>{content.ig1Title}</SectionTitle>
          <GoalBox>
            <GoalId>1.1</GoalId>
            <GoalDescription>{content.ig11Description}</GoalDescription>
            <Ig11OverviewChart data={ig11.nodes} />
            <Explore goTo={'/ig1.1'} />
          </GoalBox>
          <GoalBox>
            <GoalId>1.2</GoalId>
            <GoalDescription>{content.ig12Description}</GoalDescription>
            <Ig12OverviewChart data={ig12.nodes} />
            <Explore goTo={'/ig1.2'} />
          </GoalBox>
          <GoalBox>
            <GoalId>1.3</GoalId>
            <GoalDescription>{content.ig13Description}</GoalDescription>
            <Ig13OverviewChart data={ig13.nodes} />
            <Explore goTo={'/ig1.3'} />
          </GoalBox>
        </GoalContainer>
        {/* impact goal 2 */}
        <GoalContainer>
          <SectionTitle>{content.ig2Title}</SectionTitle>
          <GoalBox>
            <GoalId>2.1</GoalId>
            <GoalDescription>{content.ig21Description}</GoalDescription>
            <Ig21OverviewChart data={ig21.nodes} />
            <Explore goTo={'/ig2.1'} />
          </GoalBox>
          <GoalBox>
            <GoalId>2.2</GoalId>
            <GoalDescription>{content.ig22Description}</GoalDescription>
            <Ig22OverviewChart data={ig22.nodes} />
            <Explore goTo={'/ig2.2'} />
          </GoalBox>
        </GoalContainer>
        {/* impact goal 3 */}
        <GoalContainer last={true}>
          <SectionTitle>{content.ig3Title}</SectionTitle>
          <GoalBox>
            <GoalId>3.1</GoalId>
            <GoalDescription>{content.ig31Description}</GoalDescription>
            <Ig31OverviewChart data={ig31.nodes} />
            <Explore goTo={'/ig3.1'} />
          </GoalBox>
          <GoalBox>
            <GoalId>3.2</GoalId>
            <GoalDescription>{content.ig32Description}</GoalDescription>
            <Ig32OverviewChart data={ig32.nodes} />
            <Explore goTo={'/ig3.2'} />
          </GoalBox>
        </GoalContainer>

        {/* Strategic Priorities */}
        <MdHeadline color={'baseBlue'}>
          {t('Strategic Priority Indicators')}
          {` — ${t('global')}`}
        </MdHeadline>
        <IntroText dangerouslySetInnerHTML={{ __html: content.spIntroRt }} />
        <HowToRead type={'sp'} />
        {/* Strategic Priority 1 */}
        <GoalContainer>
          <SectionTitle>{content.sp1Title}</SectionTitle>
          <GoalBox noData={true} sp={true}>
            <GoalId>1.1</GoalId>
            <PriorityDescription>{content.sp11Description}</PriorityDescription>
            <NoDataPlaceholder>
              <NoData>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
            {/* <Explore /> */}
          </GoalBox>
          <GoalBox sp={true}>
            <GoalId>1.2</GoalId>
            <PriorityDescription>{content.sp12Description}</PriorityDescription>
            <Sp12OverviewChart data={sp12.nodes} />
            <Explore goTo={'/sp1.2'} />
          </GoalBox>
          <GoalBox noData={true} sp={true}>
            <GoalId>1.3</GoalId>
            <PriorityDescription>{content.sp13Description}</PriorityDescription>
            <NoDataPlaceholder>
              <NoData>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
            {/* <Explore /> */}
          </GoalBox>
          <GoalBox noData={true} sp={true}>
            <GoalId>1.4</GoalId>
            <PriorityDescription>{content.sp14Description}</PriorityDescription>
            <NoDataPlaceholder>
              <NoData>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
            {/* <Explore /> */}
          </GoalBox>
          <GoalBox sp={true}>
            <GoalId>1.6</GoalId>
            <PriorityDescription>{content.sp16Description}</PriorityDescription>
            <Sp16OverviewChart data={sp16.nodes} />
            <Explore goTo={'/sp1.6'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 2 */}
        <GoalContainer>
          <SectionTitle>{content.sp2Title}</SectionTitle>
          <GoalBox sp={true}>
            <GoalId>2.1</GoalId>
            <PriorityDescription>{content.sp21Description}</PriorityDescription>
            <Sp21OverviewChart data={sp21.nodes} />
            <Explore goTo={'/sp2.1'} />
          </GoalBox>
          <GoalBox sp={true}>
            <GoalId>2.2</GoalId>
            <PriorityDescription>{content.sp22Description}</PriorityDescription>
            <Sp22OverviewChart data={sp22.nodes} />
            <Explore goTo={'/sp2.2'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 3 */}
        <GoalContainer>
          <SectionTitle>{content.sp3Title}</SectionTitle>
          <GoalBox sp={true}>
            <GoalId>3.2</GoalId>
            <PriorityDescription>{content.sp32Description}</PriorityDescription>
            <Sp32OverviewChart data={sp32.nodes} />
            <Explore goTo={'/sp3.2'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 4 */}
        <GoalContainer>
          <SectionTitle>{content.sp4Title}</SectionTitle>
          <GoalBox sp={true}>
            <GoalId>4.1</GoalId>
            <PriorityDescription>{content.sp41Description}</PriorityDescription>
            <Sp41OverviewChart data={sp41.nodes} />
            <Explore goTo={'/sp4.1'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 5 */}
        <GoalContainer>
          <SectionTitle>{content.sp5Title}</SectionTitle>
          <GoalBox sp={true}>
            <GoalId>5.1</GoalId>
            <PriorityDescription>{content.sp51Description}</PriorityDescription>
            <Sp51OverviewChart data={sp51.nodes} />
            <Explore goTo={'/sp5.1'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 6 */}
        <GoalContainer>
          <SectionTitle>{content.sp6Title}</SectionTitle>
          <GoalBox special={true}>
            <GoalId>6.1</GoalId>
            <PriorityDescription>{content.sp61Description}</PriorityDescription>
            <NoDataPlaceholder>
              <PointerLink to={'/sp6.1'}>
                <NoData special={true}>
                  {t('click to explore data for this indicator')}
                </NoData>
                <IconBox>
                  <PointerIcon color={'inherit'} />
                </IconBox>
              </PointerLink>
            </NoDataPlaceholder>
            {/* <Explore goTo={'/sp6.1'} /> */}
          </GoalBox>
          <GoalBox sp={true}>
            <GoalId>6.2</GoalId>
            <PriorityDescription>{content.sp62Description}</PriorityDescription>
            <Sp62OverviewChart data={sp62.nodes} />
            <Explore goTo={'/sp6.2'} />
          </GoalBox>
          <GoalBox sp={true}>
            <GoalId>6.3</GoalId>
            <PriorityDescription>{content.sp63Description}</PriorityDescription>
            <Sp63OverviewChart data={sp63.nodes} />
            <Explore goTo={'/sp6.3'} />
          </GoalBox>
        </GoalContainer>
        {/* Strategic Priority 7 */}
        <GoalContainer last={true}>
          <SectionTitle>{content.sp7Title}</SectionTitle>
          <GoalBox sp={true}>
            <GoalId>7.1</GoalId>
            <PriorityDescription>{content.sp71Description}</PriorityDescription>
            <Sp71OverviewChart data={sp71.nodes} />
            <Explore goTo={'/sp7.1'} />
          </GoalBox>
          <GoalBox noData={true} sp={true}>
            <GoalId>7.2</GoalId>
            <PriorityDescription>{content.sp72Description}</PriorityDescription>
            <NoDataPlaceholder>
              <NoData>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
            {/* <Explore /> */}
          </GoalBox>
        </GoalContainer>
      </OverviewContainer>
      <Footer content={footerContent} />
    </>
  )
}
//gets all region names to dynamically make pages for each
//but we also need data for this page
//this page and overview page ({googleIgSheet.iSO3}.js) are basically the same.
//but this pages gets just global data here and the overview pages gets the data of each region (in their own query)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleOverviewCmsSheet(language: { eq: $language }) {
      language
      igIntroRt
      ig1Title
      ig13Description
      ig12Description
      ig11Description
      ig2Title
      ig21Description
      ig22Description
      ig3Title
      ig31Description
      ig32Description
      spIntroRt
      sp1Title
      sp11Description
      sp12Description
      sp13Description
      sp14Description
      sp16Description
      sp2Title
      sp21Description
      sp22Description
      sp3Title
      sp32Description
      sp4Title
      sp41Description
      sp5Title
      sp51Description
      sp6Title
      sp61Description
      sp62Description
      sp63Description
      sp7Title
      sp71Description
      sp72Description
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    ig11: allRegions2(
      filter: {
        IA2030_IND: { eq: "IG1.1" }
        CATEGORY: { eq: "GLOBAL" }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
    ig12: allRegions2(
      filter: { IA2030_IND: { eq: "IG1.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
        NUMERATOR
        DENOMINATOR
      }
    }
    ig13: allRegions2(
      filter: { IA2030_IND: { eq: "IG1.3" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }

    ig21: allRegions(
      filter: { IA2030_IND: { eq: "IG2.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
      }
    }
    ig22: allRegions(
      filter: {
        IA2030_IND: { eq: "IG2.2" }
        CATEGORY: { eq: "GLOBAL" }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
        DIMENSION2
      }
    }

    ig31: allRegions(
      filter: { IA2030_IND: { eq: "IG3.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
    ig32: allRegions(
      filter: { IA2030_IND: { eq: "IG3.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }

    sp12: allRegions(
      filter: { IA2030_IND: { eq: "SP1.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }
    sp16: allRegions(
      filter: { IA2030_IND: { eq: "SP1.6" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }
    sp21: allRegions(
      filter: { IA2030_IND: { eq: "SP2.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }
    sp22: allRegions(
      filter: { IA2030_IND: { eq: "SP2.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }

    sp32: allRegions(
      filter: { IA2030_IND: { eq: "SP3.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }

    sp41: allRegions(
      filter: {
        IA2030_IND: { eq: "SP4.1" }
        CATEGORY: { eq: "GLOBAL" }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
    sp51: allRegions2(
      filter: {
        IA2030_IND: { eq: "SP5.1" }
        CATEGORY: { eq: "GLOBAL" }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }

    sp62: allRegions(
      filter: { IA2030_IND: { eq: "SP6.2" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }

    sp63: allRegions(
      filter: { IA2030_IND: { eq: "SP6.3" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }

    sp71: allRegions(
      filter: { IA2030_IND: { eq: "SP7.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }
  }
`
export default IndexPage
//old queries
// ig11: googleIg11Sheet(memberState: { eq: "Global" }) {
//   id
//   memberState
//   iSO3
//   totalDeathAvert2019
//   totalDeathAvert2020
//   totalDeathAvert2021
//   totalDeathAvert2022
//   totalDeathAvert2023
//   totalDeathAvert2024
//   totalDeathAvert2025
//   totalDeathAvert2026
//   totalDeathAvert2027
//   totalDeathAvert2028
//   totalDeathAvert2029
// }
// ig12: googleIg12Sheet(memberState: { eq: "Global" }) {
//   id
//   memberState
//   iSO3
//   regionType
//   region

//   measles2020NotEndorsed
//   measles2020ReEstablished
//   measles2020NewlyAchieved
//   measles2020Achieved
//   measles2020NotAchieved
//   measles2021NotEndorsed
//   measles2021ReEstablished
//   measles2021NewlyAchieved
//   measles2021Achieved
//   measles2021NotAchieved
//   measles2022NotEndorsed
//   measles2022ReEstablished
//   measles2022NewlyAchieved
//   measles2022Achieved
//   measles2022NotAchieved
//   measles2023NotEndorsed
//   measles2023ReEstablished
//   measles2023NewlyAchieved
//   measles2023Achieved
//   measles2023NotAchieved
//   measles2024NotEndorsed
//   measles2024ReEstablished
//   measles2024NewlyAchieved
//   measles2024Achieved
//   measles2024NotAchieved
//   measles2025NotEndorsed
//   measles2025ReEstablished
//   measles2025NewlyAchieved
//   measles2025Achieved
//   measles2025NotAchieved
//   measles2026NotEndorsed
//   measles2026ReEstablished
//   measles2026NewlyAchieved
//   measles2026Achieved
//   measles2026NotAchieved
//   measles2027NotEndorsed
//   measles2027ReEstablished
//   measles2027NewlyAchieved
//   measles2027Achieved
//   measles2027NotAchieved
//   measles2028NotEndorsed
//   measles2028ReEstablished
//   measles2028NewlyAchieved
//   measles2028Achieved
//   measles2028NotAchieved
//   measles2029NotEndorsed
//   measles2029ReEstablished
//   measles2029NewlyAchieved
//   measles2029Achieved
//   measles2029NotAchieved
//   measles2030NotEndorsed
//   measles2030ReEstablished
//   measles2030NewlyAchieved
//   measles2030Achieved
//   measles2030NotAchieved

//   rubella2020NotEndorsed
//   rubella2020ReEstablished
//   rubella2020NewlyAchieved
//   rubella2020Achieved
//   rubella2020NotAchieved
//   rubella2021NotEndorsed
//   rubella2021ReEstablished
//   rubella2021NewlyAchieved
//   rubella2021Achieved
//   rubella2021NotAchieved
//   rubella2022NotEndorsed
//   rubella2022ReEstablished
//   rubella2022NewlyAchieved
//   rubella2022Achieved
//   rubella2022NotAchieved
//   rubella2023NotEndorsed
//   rubella2023ReEstablished
//   rubella2023NewlyAchieved
//   rubella2023Achieved
//   rubella2023NotAchieved
//   rubella2024NotEndorsed
//   rubella2024ReEstablished
//   rubella2024NewlyAchieved
//   rubella2024Achieved
//   rubella2024NotAchieved
//   rubella2025NotEndorsed
//   rubella2025ReEstablished
//   rubella2025NewlyAchieved
//   rubella2025Achieved
//   rubella2025NotAchieved
//   rubella2026NotEndorsed
//   rubella2026ReEstablished
//   rubella2026NewlyAchieved
//   rubella2026Achieved
//   rubella2026NotAchieved
//   rubella2027NotEndorsed
//   rubella2027ReEstablished
//   rubella2027NewlyAchieved
//   rubella2027Achieved
//   rubella2027NotAchieved
//   rubella2028NotEndorsed
//   rubella2028ReEstablished
//   rubella2028NewlyAchieved
//   rubella2028Achieved
//   rubella2028NotAchieved
//   rubella2029NotEndorsed
//   rubella2029ReEstablished
//   rubella2029NewlyAchieved
//   rubella2029Achieved
//   rubella2029NotAchieved
//   rubella2030NotEndorsed
//   rubella2030ReEstablished
//   rubella2030NewlyAchieved
//   rubella2030Achieved
//   rubella2030NotAchieved

//   wPV2020NotEndorsed
//   wPV2020ReEstablished
//   wPV2020NewlyAchieved
//   wPV2020Achieved
//   wPV2020NotAchieved
//   wPV2021NotEndorsed
//   wPV2021ReEstablished
//   wPV2021NewlyAchieved
//   wPV2021Achieved
//   wPV2021NotAchieved
//   wPV2022NotEndorsed
//   wPV2022ReEstablished
//   wPV2022NewlyAchieved
//   wPV2022Achieved
//   wPV2022NotAchieved
//   wPV2023NotEndorsed
//   wPV2023ReEstablished
//   wPV2023NewlyAchieved
//   wPV2023Achieved
//   wPV2023NotAchieved
//   wPV2024NotEndorsed
//   wPV2024ReEstablished
//   wPV2024NewlyAchieved
//   wPV2024Achieved
//   wPV2024NotAchieved
//   wPV2025NotEndorsed
//   wPV2025ReEstablished
//   wPV2025NewlyAchieved
//   wPV2025Achieved
//   wPV2025NotAchieved
//   wPV2026NotEndorsed
//   wPV2026ReEstablished
//   wPV2026NewlyAchieved
//   wPV2026Achieved
//   wPV2026NotAchieved
//   wPV2027NotEndorsed
//   wPV2027ReEstablished
//   wPV2027NewlyAchieved
//   wPV2027Achieved
//   wPV2027NotAchieved
//   wPV2028NotEndorsed
//   wPV2028ReEstablished
//   wPV2028NewlyAchieved
//   wPV2028Achieved
//   wPV2028NotAchieved
//   wPV2029NotEndorsed
//   wPV2029ReEstablished
//   wPV2029NewlyAchieved
//   wPV2029Achieved
//   wPV2029NotAchieved
//   wPV2030NotEndorsed
//   wPV2030ReEstablished
//   wPV2030NewlyAchieved
//   wPV2030Achieved
//   wPV2030NotAchieved

//   jev2020NotEndorsed
//   jev2020ReEstablished
//   jev2020NewlyAchieved
//   jev2020Achieved
//   jev2020NotAchieved
//   jev2021NotEndorsed
//   jev2021ReEstablished
//   jev2021NewlyAchieved
//   jev2021Achieved
//   jev2021NotAchieved
//   jev2022NotEndorsed
//   jev2022ReEstablished
//   jev2022NewlyAchieved
//   jev2022Achieved
//   jev2022NotAchieved
//   jev2023NotEndorsed
//   jev2023ReEstablished
//   jev2023NewlyAchieved
//   jev2023Achieved
//   jev2023NotAchieved
//   jev2024NotEndorsed
//   jev2024ReEstablished
//   jev2024NewlyAchieved
//   jev2024Achieved
//   jev2024NotAchieved
//   jev2025NotEndorsed
//   jev2025ReEstablished
//   jev2025NewlyAchieved
//   jev2025Achieved
//   jev2025NotAchieved
//   jev2026NotEndorsed
//   jev2026ReEstablished
//   jev2026NewlyAchieved
//   jev2026Achieved
//   jev2026NotAchieved
//   jev2027NotEndorsed
//   jev2027ReEstablished
//   jev2027NewlyAchieved
//   jev2027Achieved
//   jev2027NotAchieved
//   jev2028NotEndorsed
//   jev2028ReEstablished
//   jev2028NewlyAchieved
//   jev2028Achieved
//   jev2028NotAchieved
//   jev2029NotEndorsed
//   jev2029ReEstablished
//   jev2029NewlyAchieved
//   jev2029Achieved
//   jev2029NotAchieved
//   jev2030NotEndorsed
//   jev2030ReEstablished
//   jev2030NewlyAchieved
//   jev2030Achieved
//   jev2030NotAchieved

//   hpv2020NotEndorsed
//   hpv2020ReEstablished
//   hpv2020NewlyAchieved
//   hpv2020Achieved
//   hpv2020NotAchieved
//   hpv2021NotEndorsed
//   hpv2021ReEstablished
//   hpv2021NewlyAchieved
//   hpv2021Achieved
//   hpv2021NotAchieved
//   hpv2022NotEndorsed
//   hpv2022ReEstablished
//   hpv2022NewlyAchieved
//   hpv2022Achieved
//   hpv2022NotAchieved
//   hpv2023NotEndorsed
//   hpv2023ReEstablished
//   hpv2023NewlyAchieved
//   hpv2023Achieved
//   hpv2023NotAchieved
//   hpv2024NotEndorsed
//   hpv2024ReEstablished
//   hpv2024NewlyAchieved
//   hpv2024Achieved
//   hpv2024NotAchieved
//   hpv2025NotEndorsed
//   hpv2025ReEstablished
//   hpv2025NewlyAchieved
//   hpv2025Achieved
//   hpv2025NotAchieved
//   hpv2026NotEndorsed
//   hpv2026ReEstablished
//   hpv2026NewlyAchieved
//   hpv2026Achieved
//   hpv2026NotAchieved
//   hpv2027NotEndorsed
//   hpv2027ReEstablished
//   hpv2027NewlyAchieved
//   hpv2027Achieved
//   hpv2027NotAchieved
//   hpv2028NotEndorsed
//   hpv2028ReEstablished
//   hpv2028NewlyAchieved
//   hpv2028Achieved
//   hpv2028NotAchieved
//   hpv2029NotEndorsed
//   hpv2029ReEstablished
//   hpv2029NewlyAchieved
//   hpv2029Achieved
//   hpv2029NotAchieved
//   hpv2030NotEndorsed
//   hpv2030ReEstablished
//   hpv2030NewlyAchieved
//   hpv2030Achieved
//   hpv2030NotAchieved

//   tetanus2020NotEndorsed
//   tetanus2020ReEstablished
//   tetanus2020NewlyAchieved
//   tetanus2020Achieved
//   tetanus2020NotAchieved
//   tetanus2021NotEndorsed
//   tetanus2021ReEstablished
//   tetanus2021NewlyAchieved
//   tetanus2021Achieved
//   tetanus2021NotAchieved
//   tetanus2022NotEndorsed
//   tetanus2022ReEstablished
//   tetanus2022NewlyAchieved
//   tetanus2022Achieved
//   tetanus2022NotAchieved
//   tetanus2023NotEndorsed
//   tetanus2023ReEstablished
//   tetanus2023NewlyAchieved
//   tetanus2023Achieved
//   tetanus2023NotAchieved
//   tetanus2024NotEndorsed
//   tetanus2024ReEstablished
//   tetanus2024NewlyAchieved
//   tetanus2024Achieved
//   tetanus2024NotAchieved
//   tetanus2025NotEndorsed
//   tetanus2025ReEstablished
//   tetanus2025NewlyAchieved
//   tetanus2025Achieved
//   tetanus2025NotAchieved
//   tetanus2026NotEndorsed
//   tetanus2026ReEstablished
//   tetanus2026NewlyAchieved
//   tetanus2026Achieved
//   tetanus2026NotAchieved
//   tetanus2027NotEndorsed
//   tetanus2027ReEstablished
//   tetanus2027NewlyAchieved
//   tetanus2027Achieved
//   tetanus2027NotAchieved
//   tetanus2028NotEndorsed
//   tetanus2028ReEstablished
//   tetanus2028NewlyAchieved
//   tetanus2028Achieved
//   tetanus2028NotAchieved
//   tetanus2029NotEndorsed
//   tetanus2029ReEstablished
//   tetanus2029NewlyAchieved
//   tetanus2029Achieved
//   tetanus2029NotAchieved
//   tetanus2030NotEndorsed
//   tetanus2030ReEstablished
//   tetanus2030NewlyAchieved
//   tetanus2030Achieved
//   tetanus2030NotAchieved

//   hepb2020NotEndorsed
//   hepb2020ReEstablished
//   hepb2020NewlyAchieved
//   hepb2020Achieved
//   hepb2020NotAchieved
//   hepb2021NotEndorsed
//   hepb2021ReEstablished
//   hepb2021NewlyAchieved
//   hepb2021Achieved
//   hepb2021NotAchieved
//   hepb2022NotEndorsed
//   hepb2022ReEstablished
//   hepb2022NewlyAchieved
//   hepb2022Achieved
//   hepb2022NotAchieved
//   hepb2023NotEndorsed
//   hepb2023ReEstablished
//   hepb2023NewlyAchieved
//   hepb2023Achieved
//   hepb2023NotAchieved
//   hepb2024NotEndorsed
//   hepb2024ReEstablished
//   hepb2024NewlyAchieved
//   hepb2024Achieved
//   hepb2024NotAchieved
//   hepb2025NotEndorsed
//   hepb2025ReEstablished
//   hepb2025NewlyAchieved
//   hepb2025Achieved
//   hepb2025NotAchieved
//   hepb2026NotEndorsed
//   hepb2026ReEstablished
//   hepb2026NewlyAchieved
//   hepb2026Achieved
//   hepb2026NotAchieved
//   hepb2027NotEndorsed
//   hepb2027ReEstablished
//   hepb2027NewlyAchieved
//   hepb2027Achieved
//   hepb2027NotAchieved
//   hepb2028NotEndorsed
//   hepb2028ReEstablished
//   hepb2028NewlyAchieved
//   hepb2028Achieved
//   hepb2028NotAchieved
//   hepb2029NotEndorsed
//   hepb2029ReEstablished
//   hepb2029NewlyAchieved
//   hepb2029Achieved
//   hepb2029NotAchieved
//   hepb2030NotEndorsed
//   hepb2030ReEstablished
//   hepb2030NewlyAchieved
//   hepb2030Achieved
//   hepb2030NotAchieved

//   mNT2020NotEndorsed
//   mNT2020ReEstablished
//   mNT2020NewlyAchieved
//   mNT2020Achieved
//   mNT2020NotAchieved
//   mNT2021NotEndorsed
//   mNT2021ReEstablished
//   mNT2021NewlyAchieved
//   mNT2021Achieved
//   mNT2021NotAchieved
//   mNT2022NotEndorsed
//   mNT2022ReEstablished
//   mNT2022NewlyAchieved
//   mNT2022Achieved
//   mNT2022NotAchieved
//   mNT2023NotEndorsed
//   mNT2023ReEstablished
//   mNT2023NewlyAchieved
//   mNT2023Achieved
//   mNT2023NotAchieved
//   mNT2024NotEndorsed
//   mNT2024ReEstablished
//   mNT2024NewlyAchieved
//   mNT2024Achieved
//   mNT2024NotAchieved
//   mNT2025NotEndorsed
//   mNT2025ReEstablished
//   mNT2025NewlyAchieved
//   mNT2025Achieved
//   mNT2025NotAchieved
//   mNT2026NotEndorsed
//   mNT2026ReEstablished
//   mNT2026NewlyAchieved
//   mNT2026Achieved
//   mNT2026NotAchieved
//   mNT2027NotEndorsed
//   mNT2027ReEstablished
//   mNT2027NewlyAchieved
//   mNT2027Achieved
//   mNT2027NotAchieved
//   mNT2028NotEndorsed
//   mNT2028ReEstablished
//   mNT2028NewlyAchieved
//   mNT2028Achieved
//   mNT2028NotAchieved
//   mNT2029NotEndorsed
//   mNT2029ReEstablished
//   mNT2029NewlyAchieved
//   mNT2029Achieved
//   mNT2029NotAchieved
//   mNT2030NotEndorsed
//   mNT2030ReEstablished
//   mNT2030NewlyAchieved
//   mNT2030Achieved
//   mNT2030NotAchieved
// }
// ig13: googleIg13Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   memberState
//   region
//   choleraBaseline
//   cholera2021
//   cholera2022
//   cholera2023
//   cholera2024
//   cholera2025
//   cholera2026
//   cholera2027
//   cholera2028
//   cholera2029
//   cholera2030

//   ebolaBaseline
//   ebola2021
//   ebola2022
//   ebola2023
//   ebola2024
//   ebola2025
//   ebola2026
//   ebola2027
//   ebola2028
//   ebola2029
//   ebola2030

//   measlesBaseline
//   measles2021
//   measles2022
//   measles2023
//   measles2024
//   measles2025
//   measles2026
//   measles2027
//   measles2028
//   measles2029
//   measles2030

//   meningBaseline
//   mening2021
//   mening2022
//   mening2023
//   mening2024
//   mening2025
//   mening2026
//   mening2027
//   mening2028
//   mening2029
//   mening2030

//   cVDPVBaseline
//   cVDPV2021
//   cVDPV2022
//   cVDPV2023
//   cVDPV2024
//   cVDPV2025
//   cVDPV2026
//   cVDPV2027
//   cVDPV2028
//   cVDPV2029
//   cVDPV2030

//   wPVBaseline
//   wPV2021
//   wPV2022
//   wPV2023
//   wPV2024
//   wPV2025
//   wPV2026
//   wPV2027
//   wPV2028
//   wPV2029
//   wPV2030

//   yellowFeverBaseline
//   yellowFever2021
//   yellowFever2022
//   yellowFever2023
//   yellowFever2024
//   yellowFever2025
//   yellowFever2026
//   yellowFever2027
//   yellowFever2028
//   yellowFever2029
//   yellowFever2030
// }

// ig21: googleIg21Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   zeroDose2019
//   zeroDose2020
//   zeroDose2021
//   zeroDose2022
//   zeroDose2023
//   zeroDose2024
//   zeroDose2025
//   zeroDose2026
//   zeroDose2027
//   zeroDose2028
//   zeroDose2029
// }
// ig22: googleIg22Sheet(memberState: { eq: "Global" }) {
//   id
//   memberState
//   iSO3
//   vaccineIntro2020
//   vaccineIntro2021
//   vaccineIntro2022
//   vaccineIntro2023
//   vaccineIntro2024
//   vaccineIntro2025
//   vaccineIntro2026
//   vaccineIntro2027
//   vaccineIntro2028
//   vaccineIntro2029
//   vaccineIntro2030
// }
// ig31: googleIg31Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   dTPcv3Coverage2019
//   dTPcv3Coverage2020
//   dTPcv3Coverage2021
//   dTPcv3Coverage2022
//   dTPcv3Coverage2023
//   dTPcv3Coverage2024
//   dTPcv3Coverage2025
//   dTPcv3Coverage2026
//   dTPcv3Coverage2027
//   dTPcv3Coverage2028
//   dTPcv3Coverage2029
//   dTPcv3Coverage2030
//   pCV32019
//   pCV32020
//   pCV32021
//   pCV32022
//   pCV32023
//   pCV32024
//   pCV32025
//   pCV32026
//   pCV32027
//   pCV32028
//   pCV32029
//   pCV32030
//   mCV22019
//   mCV22020
//   mCV22021
//   mCV22022
//   mCV22023
//   mCV22024
//   mCV22025
//   mCV22026
//   mCV22027
//   mCV22028
//   mCV22029
//   mCV22030
//   hPVc2019
//   hPVc2020
//   hPVc2021
//   hPVc2022
//   hPVc2023
//   hPVc2024
//   hPVc2025
//   hPVc2026
//   hPVc2027
//   hPVc2028
//   hPVc2029
//   hPVc2030
// }
// ig32: googleIg32Sheet(memberState: { eq: "Global" }) {
//   id
//   memberState
//   iSO3
//   uhcSci2019

//   uhcSci2021

//   uhcSci2023

//   uhcSci2025

//   uhcSci2027

//   uhcSci2029
// }
// sp12: googleSp12Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   phyNurseMidwive2020
//   phyNurseMidwive2021
//   phyNurseMidwive2022
//   phyNurseMidwive2023
//   phyNurseMidwive2024
//   phyNurseMidwive2025
//   phyNurseMidwive2026
//   phyNurseMidwive2027
//   phyNurseMidwive2028
//   phyNurseMidwive2029
//   phyNurseMidwive2030
// }
// sp16: googleSp16Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   vigiAEFI2021
//   vigiAEFI2022
// }
// sp21: googleSp21Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
// sp22: googleSp22Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
// sp32: googleSp32Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   dTPCV32019
//   dTPCV32020
//   dTPCV32021
//   dTPCV32022
//   dTPCV32023
//   dTPCV32024
//   dTPCV32025
//   dTPCV32026
//   dTPCV32027
//   dTPCV32028
//   dTPCV32029
//   dTPCV32030

//   mCV12019
//   mCV12020
//   mCV12021
//   mCV12022
//   mCV12023
//   mCV12024
//   mCV12025
//   mCV12026
//   mCV12027
//   mCV12028
//   mCV12029
//   mCV12030

//   mCV22019
//   mCV22020
//   mCV22021
//   mCV22022
//   mCV22023
//   mCV22024
//   mCV22025
//   mCV22026
//   mCV22027
//   mCV22028
//   mCV22029
//   mCV22030
// }

// sp41: googleSp41Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   bOP2019
//   bOP2020
//   bOP2021
//   bOP2022
//   bOP2023
//   bOP2024
//   bOP2025
//   bOP2026
//   bOP2027
//   bOP2028
//   bOP2029
//   bOP2030
// }
// sp51: googleSp51Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   regionType
//   memberState
//   totalTimelyBaseline
//   totalTimely2021
//   totalTimely2022
//   totalUnTimelyBaseline
//   totalUnTimely2021
//   totalUnTimely2022
// }
// sp62: googleSp62Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   declineExp20182019
//   stableExp20182019
//   increaseExp20182019
//   declineExp20192020
//   stableExp20192020
//   increaseExp20192020
// }
// sp63: googleSp63Sheet(memberState: { eq: "Global" }) {
//   id
//   memberState
//   iSO3
//   decreasedExp20182019
//   decreasedExp20192020
//   decreasedExp20202021
//   decreasedExp20212022
//   increasedExp20182019
//   increasedExp20192020
//   increasedExp20202021
//   increasedExp20212022
//   stableExp20182019
//   stableExp20192020
//   stableExp20202021
//   stableExp20212022
// }
// sp71: googleSp71Sheet(memberState: { eq: "Global" }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
