import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { NUM_YEARS } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  BarLabel,
  Baseline,
  Progress,
  Target,
  Symbol,
  IconContainer,
  LeftArrow,
  ArrowHead,
  BaselineLabel,
  ArrowContainer
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { wrangle31Data } from '../../utils/helpers'

const Ig31OverviewChart = ({ data }) => {
  const { t } = useTranslation()
  const ig31Data = wrangle31Data(data)
  const goal = 90

  const matches = useMediaQuery({ query: '(max-width: 740px)' })
  const scale = 90

  const order = ['DTP3', 'PCV3', 'MCV2', 'HPVc']

  return (
    <VizContainer>
      {order.map((key, i) => {
        const value = ig31Data[key]
        const baseline = value[0].value
        const currValue =
          value[value.length - 1].value >= 0 ? value[value.length - 1].value : 0
        const currYear = value[value.length - 1].year
        //don't count 2019
        const numYearsInData = value.length - 1
        const goalPerYear = (goal - baseline) / NUM_YEARS
        //console.log('gpy', goalPerYear)
        const currNeededToBeOnTarget =
          currValue >= 90 ? 0 : baseline + numYearsInData * goalPerYear
        //console.log(key)
        //console.log(currNeededToBeOnTarget)
        const onTarget = currValue >= currNeededToBeOnTarget
        // console.log('currValue', currValue)

        return (
          <React.Fragment key={`${key}a`}>
            {matches ? <Label>{t(key)}</Label> : null}
            <ChartRow key={key}>
              {!matches ? <Label>{t(key)}</Label> : null}
              <Bar
                //rounded={true}
                //right={true}
                mr={'2.9rem'}
                role="img"
                alt={`${t(
                  'horizontal bar chart showing progress'
                )} ${currValue}% ${t('toward goal of 90% coverage.')}`}
              >
                <Baseline left={Math.min((baseline / scale) * 100, 100)} />
                <Progress
                  progress={Math.min((currValue / scale) * 100, 100)}
                  //mr={'2.9rem'}
                  color={
                    currYear >= 2021
                      ? onTarget
                        ? 'brightBlue'
                        : 'darkOrange'
                      : 'untracked'
                  }
                  //rounded={currValue * 100 > 97 ? true : false}
                  // right={true}
                >
                  <BarLabel
                    hide={currValue <= 5}
                    currValue={currValue}
                  >{`${currValue.toFixed()}%`}</BarLabel>
                </Progress>
                <Target
                  right={'-2.9rem'}
                  success={currValue >= 90}
                  adjust={true}
                >
                  {!i ? '90%' : ''}
                </Target>
              </Bar>
              <Symbol>
                {currYear >= 2021 ? (
                  onTarget ? (
                    <IoCheckmarkCircle
                      color={'#4681de'}
                      size={42}
                      role="img"
                      alt="on target"
                    />
                  ) : (
                    <BsFillExclamationDiamondFill
                      color={'#d96038'}
                      size={42}
                      role="img"
                      alt="off target"
                    />
                  )
                ) : (
                  <IoCheckmarkCircle color={'transparent'} size={42} />
                )}
              </Symbol>
            </ChartRow>
          </React.Fragment>
        )
      })}
      <ChartRow marginTop={'-1rem'}>
        {!matches ? <Label></Label> : null}

        <IconContainer
          position={'flex-start'}
          marginRight={'2.9rem'}
          widthSm={'65%'}
          widthXs={'54%'}
        >
          <ArrowContainer
            left={
              (ig31Data['HPVc'][ig31Data['HPVc'].length - 1].value / scale) *
                100 +
              (matches ? 0 : 2)
            }
          >
            <ArrowHead />
            <LeftArrow />
          </ArrowContainer>
          <BaselineLabel
            left={
              (ig31Data['HPVc'][ig31Data['HPVc'].length - 1].value / scale) *
                100 +
              5
            }
            offset={'-20px'}
          >
            {t('vaccination coverage in baseline year')}
            &nbsp;
          </BaselineLabel>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig31OverviewChart
