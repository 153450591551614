import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
//import { NUM_COUNTRIES_IN_REG, GBL_NUM_COUNTRIES } from '../../utils/const'
import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Progress,
  BarLabel,
  Baseline,
  Blank,
  Symbol
} from '../../styles/OverviewChartStyles'
import { IoCheckmarkCircle } from 'react-icons/io5'
//import { wrangleSPResponseData } from '../../utils/helpers'
import { group } from 'd3-array'

const Sp71OverviewChart = ({ data }) => {
  const { t } = useTranslation()

  //const sp71Data = wrangleSPResponseData(data)
  // const scale = NUM_COUNTRIES_IN_REG['GBL']
  //const baseline = (sp71Data[0].yes / scale) * 100

  //most recent value
  //const yesVal = sp71Data[sp71Data.length - 1].yes
  //const noVal = sp71Data[sp71Data.length - 1].no

  //const mostRecentValue = (yesVal / scale) * 100
  //const mostRecentLabel = (yesVal / (yesVal + noVal)) * 100

  //last year's value
  //const yesLastVal =
  // sp71Data.length > 1 ? sp71Data[sp71Data.length - 2].yes : null
  //const lastYearsValue = yesLastVal ? (yesLastVal / scale) * 100 : baseline

  //const noData = (sp71Data[sp71Data.length - 1].nDNRNoResponse / scale) * 100
  const dataMap = group(data, (d) => d.YEAR)
  const dataByYear = Array.from(dataMap.values())
  const baseline = dataByYear[0].find((d) => d.DIMENSION2 === 'YES').VALUE_NUM

  const mostRecentValue = dataByYear[dataByYear.length - 1].find(
    (d) => d.DIMENSION2 === 'YES'
  ).VALUE_NUM

  const lastYearsValue = dataByYear[dataByYear.length - 2].find(
    (d) => d.DIMENSION2 === 'YES'
  ).VALUE_NUM

  const noDataValue = dataByYear[dataByYear.length - 1].find(
    (d) => d.DIMENSION2 === 'ND_NR_NORESPONSE'
  ).VALUE_NUM

  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  return (
    <VizContainer>
      <ChartRow>
        {/* spacing placeholder */}
        {!matches ? <Label></Label> : null}
        <Bar
          role="img"
          alt={`${t('horizontal bar chart showing')} ${
            mostRecentValue.toFixed
          } ${t('% of countries with an immunization research agenda.')}`}
        >
          <Baseline left={baseline} />
          <Progress
            progress={mostRecentValue}
            color={
              mostRecentValue > lastYearsValue
                ? 'mint'
                : mostRecentValue < lastYearsValue
                ? 'yellow'
                : 'gray'
            }
          >
            <BarLabel>{mostRecentValue.toFixed()}%</BarLabel>
          </Progress>

          <Blank width={`${noDataValue}%`} />
        </Bar>
        <Symbol>
          {/* this is invisible placeholder so spacing between bars comes out right */}
          <IoCheckmarkCircle color={'transparent'} size={42} />
        </Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Sp71OverviewChart
