import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'

import { VizContainer } from '../../styles/OverviewStyles'
import {
  ChartRow,
  Label,
  Bar,
  Baseline,
  BaselineLabel,
  ProgressVariableStart,
  Symbol,
  IconContainer,
  ArrowContainer,
  RightArrow,
  ArrowHead
} from '../../styles/OverviewChartStyles'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
//import { wrangle32Data } from '../../utils/helpers'

const Ig32OverviewChart = ({ data }) => {
  const { t } = useTranslation()
  // const wrangleIg32 = (data) => {
  //   return Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'region']
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         return { year, value }
  //       } else return null
  //     })
  //     .filter((item) => item)
  // }
  //const ig32Data = wrangle32Data(data)

  // const dummyData = [
  //   { year: 2019, value: 48 },
  //   { year: 2020, value: 45 },
  //   { year: 2021, value: 42 }
  // ]
  const currYear = data[data.length - 1].YEAR
  const mostRecentValue = data[data.length - 1].VALUE_NUM.toFixed() //dummyData[dummyData.length - 1].value
  const baseline = data[0].VALUE_NUM.toFixed() //dummyData[0].value//2019 value
  const scale = 20 //(+/-30)

  const isSmall = useMediaQuery({ query: '(max-width: 740px)' })
  const isXSmall = useMediaQuery({ query: '(max-width: 450px)' })

  return (
    <VizContainer>
      {isSmall ? <Label>{t('uhc index in most recent year')}</Label> : null}
      <ChartRow>
        {!isSmall ? <Label>{t('uhc index in most recent year')}</Label> : null}
        <Bar
          rounded={false}
          role="img"
          alt={`${t(
            'horizontal bar chart showing value of'
          )} ${mostRecentValue}`}
        >
          <Baseline left={50} />
          <ProgressVariableStart
            left={
              mostRecentValue - baseline > 0
                ? (10 / scale) * 100
                : ((mostRecentValue - baseline + 10) / scale) * 100
            }
            progress={
              mostRecentValue
                ? Math.abs((mostRecentValue - baseline) / scale) * 100
                : 0
            }
            color={
              currYear >= 2021
                ? mostRecentValue - baseline > 0
                  ? 'brightBlue'
                  : 'darkOrange'
                : 'gray'
            }
          />
        </Bar>
        <Symbol>
          {currYear >= 2021 && mostRecentValue - baseline !== 0 ? (
            mostRecentValue - baseline > 0 ? (
              <IoCheckmarkCircle
                color={'#4681de'}
                size={42}
                role="img"
                alt="on target"
              />
            ) : (
              <BsFillExclamationDiamondFill
                color={'#d96038'}
                size={42}
                role="img"
                alt="off target"
              />
            )
          ) : (
            <BsFillExclamationDiamondFill color={'transparent'} size={42} />
          )}
        </Symbol>
      </ChartRow>
      <ChartRow marginTop={'-1rem'}>
        {!isSmall ? <Label></Label> : null}

        <BaselineLabel
          right={isSmall ? 65 : 43}
          offset={isXSmall ? '-30px' : isSmall ? '-17px' : '-20px'}
          width={'unset'}
        >
          {t('baseline UHC index of')}&nbsp;
          {`${baseline}`}
        </BaselineLabel>
        <IconContainer position={'flex-end'}>
          <ArrowContainer right={50}>
            <ArrowHead rightArrow={true} />
            <RightArrow />
          </ArrowContainer>
        </IconContainer>
        <Symbol></Symbol>
      </ChartRow>
    </VizContainer>
  )
}

export default Ig32OverviewChart
